/**
 *
 * The Pyodide version.
 *
 * The version here follows PEP440 which is different from the one in package.json,
 * as we want to compare this with the version of Pyodide Python package without conversion.
 */
const version: string = "0.21.3";

export default version;
